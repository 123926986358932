import axios from "axios";
import {cleanPalmBeachInfo, cleanClubInfo, cleanSpouseInfo, cleanSponsorInfo} from "../helpers/formCleaning";



export const submitHubspotForm = async (formSubmission) => {
  let { agreement, pb_same, other_interest, ...fields } = formSubmission;

  fields = cleanPalmBeachInfo(fields);
  fields = cleanClubInfo(fields);
  fields = cleanSpouseInfo(fields);

  const fieldArray = [
    {
      name: "application_status",
      value: "Application Received",
    },
  ];

  for (const [key, value] of Object.entries(fields)) {
    if (key.includes("_birth_day")) {
      continue;
    } else if (key.includes("_birth_month")) {
      continue;
    } else if (key.includes("_birth_year")) {
      continue;
    } else if (key === "interests") {
      fieldArray.push({ name: key, value: [...value, other_interest].toString() });
      continue;
    } else if (key.includes("date_of_birth")) {
      fieldArray.push({
        name: key,
        value: `${value.day} ${value.month} ${value.year}`,
      });
    } else {
      fieldArray.push({ name: key, value: value });
    }
  }

  const portalId = "20720310"; // example portal ID (not real)
  const formGuid = "37f557f7-1ef5-445a-a6cf-e49c3bf86357"; // example form GUID (not real)
  const config = {
    // important!
    headers: {
      "Content-Type": "application/json",
    },
  };

  console.log(fieldArray);

  return axios.post(
    `https://api.hsforms.com/submissions/v3/integration/submit/${portalId}/${formGuid}`,
    {
      portalId,
      formGuid,
      fields: fieldArray,
    },
    config
  );
};
