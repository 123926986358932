import {
  Heading,
  Accordion,
  AccordionPanel,
  Box,
  Text,
  Paragraph,
} from "grommet";
import React, { useState, Fragment, useEffect } from "react";
import "../assets/styles/styles.scss";
import FormBlock from "../components/FormBlock";
import RadioInput from "../components/RadioInput";
import { FormDown, FormUp } from "grommet-icons";
import { useSelector, useDispatch } from "react-redux";
import { setStage } from "../state/globalSlice";
import { Image } from "grommet";
import {
  cleanPalmBeachInfo,
  cleanClubInfo,
  cleanSpouseInfo,
  cleanSponsorInfo,
} from "../helpers/formCleaning";

const PanelHeader = (title, active, stage) => {
  const dispatch = useDispatch();
  const editSection = () => {
    dispatch(setStage(stage));
  };

  return (
    <Box
      direction="row"
      align="center"
      justify="between"
      pad="16px"
      margin={{ top: "12px" }}
      gap="small"
      border={{ side: "all", color: "grey", size: "1px" }}>
      <Box direction="row" gap="5px">
        <Text color="brand">{title}</Text>
        <Text color="brand">{"|"}</Text>
        <Text color="red" onClick={editSection}>
          {"Edit"}
        </Text>
      </Box>

      {active ? <FormUp color="brand" /> : <FormDown color="brand" />}
    </Box>
  );
};

const Panel = ({ children }) => {
  return (
    <Box
      pad={{ horizontal: "16px", vertical: "24px" }}
      margin="none"
      border={[
        { size: "1px", color: "grey", side: "bottom" },
        {
          size: "1px",
          color: "grey",
          side: "vertical",
        },
      ]}>
      {children}
    </Box>
  );
};

const Item = ({ label, value }) => {
  return (
    <Box>
      <Paragraph
        margin={{ top: "none", bottom: "3px" }}
        className="smallPrint straight"
        style={{ opacity: 0.5 }}>
        {label}
      </Paragraph>
      {Array.isArray(value) ? (
        value.map((item, index) => {
          return (
            <Paragraph
              key={index}
              margin={{
                top: "none",
                bottom: index === value.length - 1 ? "26px" : "none",
              }}
              className="small straight">
              {item}
            </Paragraph>
          );
        })
      ) : (
        <Paragraph
          margin={{ top: "none", bottom: "26px" }}
          className="small straight">
          {value}
        </Paragraph>
      )}
    </Box>
  );
};

const renderChildren = (form) => {
  const ChildrenComponent = [];

  [...Array(8)].map((x, i) => {
    form[`child_${i}_name`] &&
      ChildrenComponent.push(
        <Box key={i}>
          <Item label={`Child ${i + 1} Name`} value={`${form[`child_${i}_title`]} ${form[`child_${i}_name`]}`} />
          <Item
            label={`Child ${i + 1} Date of Birth`}
            value={`${form[`child_${i}_date_of_birth`].month} ${
              form[`child_${i}_date_of_birth`].day
            } ${form[`child_${i}_date_of_birth`].year}`}
          />

          <Item label={`Child ${i + 1} Headshot`} value=""/>
                  <Image
                    src={form[`child_${i}_headshot`]}  
                    width="100%"
                    height="auto"
                  />
          <Item label={`Child ${i + 1} Gender`} value={form[`child_${i}_gender`]} />
          <Item label={`Child ${i + 1} Email`} value={form[`child_${i}_email`]} />
          <Item label={`Child ${i + 1} Mobile`} value={form[`child_${i}_mobile`]} />

          <Item
            label={`Child ${i + 1} Charging Privileges`}
            value={form[`child_${i}_charging_privileges`] ? "Yes" : "No"}
          />
        </Box>
      );
  });

  if (ChildrenComponent.length) {
    return ChildrenComponent;
  } else {
    return (
      <Paragraph margin="none" className="smallPrint straight">
        No children
      </Paragraph>
    );
  }
};

const ReviewPersonal = ({ control }) => {
  const [activeIndex, setActiveIndex] = useState([]);
  const form = useSelector((state) => state.form);

  return (
    <>
      <Heading fill level={2}>
        Review
      </Heading>

      <FormBlock title={"Please review your application details:"}>
        <Accordion onActive={(value) => setActiveIndex(value)}>
          <AccordionPanel
            header={PanelHeader(
              "Personal Information",
              activeIndex.includes(0),
              0
            )}>
            <Panel>
              <Item
                label={"Full Name"}
                value={`${form.title} ${form.firstname} ${form.lastname}`}
              />
              <Item
                label={"Date of Birth"}
                value={`${form.date_of_birth.month} ${form.date_of_birth.day} ${form.date_of_birth.year}`}
              />
              <Item label={"Gender"} value={form.contact_gender} />
              <Item label={"EU Shoe Size"} value={form.eu_shoe_size} />
              <Item label={"Country"} value={form.nationality} />
              <Item label={"Headshot/Photo"} value=""/>
              <Image
                src={form.headshot_photo_upload}
                width="100%"
                height="auto"
              />
              <Item
                label={"Relationship Status"}
                value={form.relationship_status}
              />
              <Item
                label={"Residence Address"}
                value={[
                  form.street,
                  form.city,
                  form.state,
                  form.country,
                  form.zip,
                ]}
              />
              <Item
                label={"Do You Reside In Palm Beach?"}
                value={[form.reside_in_palmbeach]}
              />
              {form.reside_in_palmbeach === "Seasonally" && (
                <Item
                  label={"Local Palm Beach Residence Address"}
                  value={[
                    form.pb_street,
                    form.pb_city,
                    form.pb_state,
                    form.pb_country,
                    form.pb_zip,
                  ]}
                />
              )}
              <Item label={"Email"} value={form.email} />
              <Item label={"Mobile"} value={form.mobile} />
              {form.telephone !== "" && <Item label={"Telephone"} value={form.telephone} />}
            </Panel>
          </AccordionPanel>
          <AccordionPanel
            header={PanelHeader(
              "Spouse Information",
              activeIndex.includes(1),
              0
            )}>
            <Panel>
              {form.relationship_status !== "Single" &&
              form.relationship_status !== "Prefer not to say" ? (
                <>
                  <Item
                    label={"Full Name"}
                    value={`${form.spouse_title} ${form.spouse_first_name} ${form.spouse_last_name}`}
                  />
                  <Item
                    label={"Date of Birth"}
                    value={`${form.spouse_date_of_birth.month} ${form.spouse_date_of_birth.day} ${form.spouse_date_of_birth.year}`}
                  />
                  <Item label={"Headshot/Photo"} value=""/>
                  <Image
                    src={form.spouse_headshot}
                    width="100%"
                    height="auto"
                  />
                  <Item label={"Gender"} value={form.spouse_gender} />
                  <Item label={"Spouse EU Shoe Size"} value={form.spouse_eu_shoe_size} />
                  <Item label={"Email"} value={form.spouse_email} />
                  <Item label={"Mobile"} value={form.spouse_mobile} />
                  {form.spouse_telephone !== "" && <Item label={"Telephone"} value={form.spouse_telephone} />}
                </>
              ) : (
                <Paragraph margin="none" className="smallPrint straight">
                  No spouse
                </Paragraph>
              )}
            </Panel>
          </AccordionPanel>

          <AccordionPanel
            header={PanelHeader(
              "Child Information",
              activeIndex.includes(2),
              0
            )}>
            <Panel>{renderChildren(form)}</Panel>
          </AccordionPanel>
          <AccordionPanel
            header={PanelHeader(
              "Business Information",
              activeIndex.includes(3),
              0
            )}>
            <Panel>
              <Item label={"Occupation Title"} value={form.occupation} />
              <Item label={"Industry"} value={form.industry} />
              <Item label={"Company Name"} value={form.company} />
              {form.linked_in && (
                <Item label={"LinkedIn Url"} value={form.linked_in} />
              )}
            </Panel>
          </AccordionPanel>
          <AccordionPanel
            header={PanelHeader(
              "References & Interests",
              activeIndex.includes(4),
              1
            )}>
            <Panel>
              <Item
                label={"Clubs"}
                value={
                  form.other_clubs === "Yes"
                    ? form.clubs
                    : "Not a member of any other clubs"
                }
              />
              <Item label={"About You"} value={form.about_you} />
              <Item
                label={"Interests"}
                value={`${form.interests.join(", ")}${form.other_interest !== "" ? `${form.interests.length ? "," : ""} ${form.other_interest}` : ""}`}
              />
            </Panel>
          </AccordionPanel>
          <AccordionPanel
            header={PanelHeader("Proposer", activeIndex.includes(5), 2)}>
            <Panel>
              <>
                <Item label={"Proposer Name"} value={form.proposer_name} />
                <Item label={"Proposer Email"} value={form.proposer_email} />
                <Item
                  label={"How Long Have You Known Them"}
                  value={form.proposer_how_long}
                />
              </>
            </Panel>
          </AccordionPanel>
        </Accordion>
      </FormBlock>
    </>
  );
};

export default ReviewPersonal;
