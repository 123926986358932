import { Heading } from "grommet";
import React, { useEffect } from "react";
import "../assets/styles/styles.scss";
import FormBlock from "../components/FormBlock";
import RadioInputWithInfo from "../components/RadioInputWithInfo";
import { useWatch } from "react-hook-form";

const ReviewPersonal = ({ control, setValue }) => {


  return (
    <>
      <Heading fill level={2}>
        Documents
      </Heading>

      <FormBlock
        title={
          "Please confirm how you would like to receive and sign your Membership Admission Documents:"
        }>
         <RadioInputWithInfo
          name={"receive_documents"}
          setValue={setValue}
          control={control}
          options={[
            { label: "E-Sign", info: "I prefer to review and sign my Membership Admission Documents with HelloSign." },
            { label: "Email or Mail", info: "I prefer to receive my Membership Admission Documents via email or mail, review and sign offline, then return via email or mail." },
          ]}
          direction="column"
        />

      </FormBlock>
    </>
  );
};

export default ReviewPersonal;
