export const cleanPalmBeachInfo = (fields) => {
  const { other_clubs } = fields;
  if (other_clubs !== "Yes") {
    fields["clubs"] = "";
  }
  return fields;
};

export const cleanClubInfo = (fields) => {
  const { reside_in_palmbeach } = fields;
  if (reside_in_palmbeach !== "Seasonally") {
    fields["pb_street"] = "";
    fields["pb_city"] = "";
    fields["pb_state"] = "";
    fields["pb_country"] = "";
    fields["pb_zip"] = "";
  }
  return fields;
};

export const cleanSpouseInfo = (fields) => {
  const { relationship_status } = fields;
  if (
    relationship_status === "Single" ||
    relationship_status === "Prefer not to say"
  ) {
    fields["spouse_date_of_birth_day"] = { day: "", month: "", year: "" };
    fields["spouse_date_of_birth_month"] = "";
    fields["spouse_date_of_birth_year"] = "";
    fields["spouse_date_of_birth"] = "";
    fields["spouse_first_name"] = "";
    fields["spouse_last_name"] = "";
    fields["spouse_telephone"] = "";
    fields["spouse_title"] = "";
    fields["spouse_email"] = "";
  }
  return fields;
};

export const cleanSponsorInfo = (fields) => {
  const { sponsorship_type } = fields;
  if (sponsorship_type === "Proposed") {
    fields["sponsor_1_email"] = "";
    fields["sponsor_1_full_name"] = "";
    fields["sponsor_1_how_long"] = "";
    fields["letter_support_1"] = "";
    fields["sponsor_2_email"] = "";
    fields["sponsor_2_full_name"] = "";
    fields["sponsor_2_how_long"] = "";
    fields["letter_support_2"] = "";
  } else {
    fields["proposer_email"] = "";
    fields["proposer_how_long"] = "";
    fields["proposer_name"] = "";
  }
  return fields;
};
