import { Box, ResponsiveContext } from "grommet";
import React, { useEffect, useContext } from "react";
import { useForm, useWatch } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import ForwardIcon from "../assets/images/ic_arrow_forward.inline.svg";
import swordfish from "../assets/images/swordfish@2x.png";
import "../assets/styles/styles.scss";
import Button from "../components/Button";
import Footer from "../components/Footer";
import FormBlock from "../components/FormBlock";
import HorizontalRule from "../components/HorizontalRule";
import PageHeader from "../components/PageHeader";
import PageTitle from "../components/PageTitle";
import ReviewAgreement from "../formBlocks/reviewAgreement";
import ReviewDocuments from "../formBlocks/reviewDocuments";
import ReviewPersonal from "../formBlocks/reviewPersonal";
import BackButton from "../components/BackButton";

import { setStage, goBack } from "../state/globalSlice";
import { saveForm } from "../state/formSlice";
import { submitHubspotForm } from "../helpers/hubspotForm";

function Layout(props) {
  const {
    handleSubmit,
    control,
    formState,
    setError,
    getValues,
    setValue,
    reset,
  } = useForm();

  const form = useSelector((state) => state.form);
  const dispatch = useDispatch();

  const size = useContext(ResponsiveContext);
  const isMobile = size === "small" || size === "xsmall" ? true : false;

  const onSubmit = (values) => {
    submitHubspotForm({...form, ...values})
      .then((response) => {
        dispatch(setStage(4));
      })
      .catch((error) => {
        alert(error);
      });
  };

  return (
    <Box fill="horizontal">
      <PageHeader activeSection={4} />
      <Box
        width={{ max: "952px" }}
        fill="horizontal"
        alignSelf="center"
        align="center"
        pad={{ horizontal: isMobile ? "24px" : "36px", top: isMobile ? "104px" : "250px" }}
        animation="fadeIn">
        <PageTitle
          title={`Review &\nSubmit Application`}
          image={swordfish}
          body={
            "Please review and submit your application. Once reviewed by our team, you will receive a separate email containing your Membership Admission documents to sign."
          }
        />
        <HorizontalRule />
        <Box fill>
          <form onSubmit={handleSubmit(onSubmit)}>
            <ReviewPersonal
              formState={formState}
              control={control}
              setValue={setValue}
            />
            <HorizontalRule />
            <ReviewDocuments
              formState={formState}
              control={control}
              setValue={setValue}
            />
            <HorizontalRule />
            <ReviewAgreement
              formState={formState}
              control={control}
              setValue={setValue}
            />

            <FormBlock margin={{ top: "35px" }} backButton>
              <Box direction="row" gap="medium">
                {isMobile && <BackButton onClick={() => dispatch(goBack())} />}
                <Button
                  type="submit"
                  color={"red"}
                  text={"Submit"}
                  icon={<ForwardIcon />}
                />
              </Box>
            </FormBlock>
          </form>
          <Footer />
        </Box>
      </Box>
    </Box>
  );
}

export default Layout;
