import { Box, Heading, Paragraph } from "grommet";
import React, { useEffect, useState } from "react";
import "../assets/styles/styles.scss";
import CheckBoxComponent from "../components/CheckBoxInput";
import FormBlock from "../components/FormBlock";
const ReviewPersonal = ({ control }) => {
  const [error, setError] = useState(false);

  return (
    <>
      <Heading fill level={2}>
        Agreement
      </Heading>

      <FormBlock title={"Please read and accept the following agreement:"}>
        <Box
          pad="15px"
          border={{ side: "all", size: error ? "2px" : "1px", color: error ? "#CE614A" : "grey" }}>
          <CheckBoxComponent
            small
            required
            control={control}
            callBack={setError}
            name={"agreement"}
            label={"I hereby apply for election to Carriage House"}
          />
          <Paragraph className="terms straight">{`I authorize those persons or entities set forth in this statement to furnish all information requested by the Club and agree to hold the Club harmless from any and all such acts. I understand that Membership in the Club is by invitation and in order to acquire a Membership in the Club, I must be invited for Membership.`}</Paragraph>
          <Paragraph className="terms straight">{`Membership is contingent upon approval by the Club, which approval shall be at its discretion. All information contained within this Membership Candidate Information Statement will be kept confidential by the Club except as otherwise required by law.`}</Paragraph>
          <Paragraph className="terms straight">{`By ticking this box, I Authorize Carriage House Club to contact references and verify the information provided in this form.`}</Paragraph>
        </Box>
      </FormBlock>
    </>
  );
};

export default ReviewPersonal;
